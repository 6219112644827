<template>
  <div>
    <f-master-select
      v-model="selectedMasters"
      :isMultiple="isMultiple"
      :readonly="readonly"
      :optional="optional"
      :keepHeight="keepHeight"
      :placeholder="placeholder"
      :noCounter="noCounter"
      :selectAttrs="selectAttrs"
      :icon="icon"
      ref="masterSelectComponent" />
  </div>
</template>

<script>
import FMasterSelect from './MasterSelect.vue';

export default {
  name: 'BRMasterSelect',
  components: {
    FMasterSelect,
  },
  props: {
    value: {
      type: [Array, String],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    keepHeight: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    noCounter: {
      type: Boolean,
      default: false,
    },
    selectAttrs: {
      type: Object,
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    selectedMasters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.masterSelectComponent) {
        const originalGet = this.$refs.masterSelectComponent.get;

        this.$refs.masterSelectComponent.get = async function () {
          await originalGet.call(this);

          this.countries = this.countries.filter(
            (country) =>
              country.name.en === 'BRAZIL' || country.name.pt === 'BRASIL'
          );

          if (this.value) {
            this.preSelect();
          }
        };

        this.$refs.masterSelectComponent.get();
      }
    });
  },
};
</script>

<template>
  <div>
    <!-- Seleção de tipo de usuário -->
    <v-select
      v-model="user.type"
      :items="[
        { text: $i18n.t('labels.student'), value: 'student' },
        { text: 'Master', value: 'master' },
        { text: 'FACT', value: 'fact' },
        { text: 'TSM', value: 'tsm' },
        { text: 'Admin', value: 'admin' },
      ]"
      :label="$tt.capitalize($t('labels.userType'))"
      :rules="valid"
      required
      outlined
      hide-details />
    <br />

    <v-select
      class="mb-9"
      v-if="user.type == 'student' || user.type == 'fact'"
      v-model="userRegion"
      :items="[
        { text: 'BR', value: 'br' },
        { text: 'LAAM', value: 'laam' },
      ]"
      :label="$tt.capitalize($t('labels.region'))"
      :rules="valid"
      required
      outlined
      hide-details
      @change="onChangeRegion" />

    <f-br-master-select
      v-if="
        (user.type == 'student' || user.type == 'fact') && user.region === 'br'
      "
      v-model="user.masters"
      :isMultiple="false" />

    <f-laam-master-select
      v-else-if="
        (user.type == 'student' || user.type == 'fact') &&
        user.region === 'laam'
      "
      v-model="user.masters"
      :isMultiple="false" />

    <div v-if="user.type == 'tsm'">
      <v-select
        v-model="user.TSM.countries"
        item-value="_id"
        item-text="name"
        :items="countriesList"
        :label="$tt.capitalize($t('labels.country'))"
        :rules="valid"
        required
        @change="setTSMRegion()"
        outlined
        multiple
        chips />
      <div v-if="user.TSM.countries.includes(brazilCountryID) !== false">
        <v-select
          v-model="user.TSM.regions"
          :items="TSMStates"
          :label="$tt.capitalize($t('labels.states'))"
          outlined
          multiple
          chips />
      </div>
    </div>
  </div>
</template>

<script>
import FMasterSelect from '../../shared/MasterSelect.vue';
import FBrMasterSelect from '../../shared/BRMasterSelect.vue';
import FLaamMasterSelect from '../../shared/LAAMMasterSelect.vue';
import validatorRules from '../../../helpers/validator_rules';
import { UserType } from '../../../helpers/user_utils';

export default {
  components: {
    FMasterSelect,
    FBrMasterSelect,
    FLaamMasterSelect,
  },
  data: () => ({
    TSMStates: [],
    brazilCountryID: '',
  }),
  computed: {
    showButtons() {
      let buttons = {
        tsm: false,
        admin: false,
      };

      if (this.authUser.type == UserType.Admin) {
        buttons.tsm = buttons.admin = true;
      }

      return buttons;
    },
    showSelect() {
      let selects = {
        master: false,
      };

      if (
        this.user.type == UserType.Master &&
        (this.authUser.type == UserType.Admin ||
          this.authUser.type == UserType.TSM)
      ) {
        selects.master = true;
      }

      return selects;
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
    user() {
      return this.$store.state.user.view;
    },
    countriesList() {
      const countries = this.$store.state.country.list;
      if (typeof countries.count != 'undefined' && countries.count > 0) {
        var list = countries.items.map((country) => {
          if (country.name.en == 'BRAZIL') {
            this.brazilCountryID = country._id;
          }
          return {
            _id: country._id,
            name: country.name[this.$i18n.locale],
          };
        });
        return list;
      } else return [];
    },
    valid() {
      return validatorRules.required(this.$t('rules.required'));
    },
    userRegion: {
      get() {
        // Se o dialCode for +55 e a região não estiver definida, definimos como 'br'
        if (
          !this.user.region &&
          this.$store.state.user.view.dialCode === '+55'
        ) {
          this.$nextTick(() => {
            this.$store.commit('user/updateViewField', {
              field: 'region',
              value: 'br',
            });
          });
          return 'br';
        }
        // Se a região não estiver definida e o dialCode não for +55, definimos como 'laam'
        else if (!this.user.region) {
          this.$nextTick(() => {
            this.$store.commit('user/updateViewField', {
              field: 'region',
              value: 'laam',
            });
          });
          return 'laam';
        }

        // Retorna o valor existente se estiver definido
        return this.user.region;
      },
      set(value) {
        this.$store.commit('user/updateViewField', { field: 'region', value });
        this.onChangeRegion();
      },
    },
  },
  created() {
    this.$store.dispatch('country/loadList');
  },
  methods: {
    async setTSMRegion() {
      if (this.user.TSM.countries.includes(this.brazilCountryID)) {
        const result = await this.$store.dispatch(
          'country/listStates',
          this.brazilCountryID
        );
        this.TSMStates = result;
      } else {
        this.TSMStates = [];
        this.user.TSM.regions = [];
      }
    },
    onChangeRegion() {
      // Resetar o master selecionado quando a região muda
      if (this.user.masters) {
        this.$store.commit('user/updateViewField', {
          field: 'masters',
          value: null,
        });
      }

      // Forçar a reatividade atualizando o componente
      this.$forceUpdate();
    },
  },
  watch: {
    userRegion(newValue) {
      // Garantir que user.region esteja sincronizado com userRegion
      if (this.user.region !== newValue) {
        this.$store.commit('user/updateViewField', {
          field: 'region',
          value: newValue,
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <f-group-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            v-model="user.name"
            name="user-edit-name"
            :label="$tt.capitalize($t('labels.name'))" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            name="user-edit-email"
            v-model="user.email"
            :label="$tt.capitalize($t('labels.email'))"
            :rules="validEmail"
            :disabled="$store.state.auth.user.type == 'student'" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            v-model="user.country"
            name="user-country"
            item-value="_id"
            item-text="name"
            :items="countriesList"
            :label="$tt.capitalize($t('labels.country'))"
            :rules="valid"
            required
            @change="
              clearStateAndCity();
              getStates();
              setDialCode();
            "
            outlined
            :disabled="isLoading" />
        </v-col>
        <v-col cols="12" sm="4">
          <div v-if="states.length">
            <v-select
              v-model="user.state"
              name="user-state"
              :items="states"
              :label="$tt.capitalize($t('labels.states'))"
              :rules="belongsToBrazil()"
              @change="getCities"
              outlined
              :disabled="isLoading" />
          </div>
          <div v-else>
            <v-text-field
              v-model="user.state"
              outlined
              name="user-state"
              :disabled="isLoading"
              :label="$tt.capitalize($t('labels.states'))"
              :rules="belongsToBrazil()" />
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div v-if="cities.length">
            <v-autocomplete
              v-model="user.city"
              outlined
              name="user-city"
              :disabled="isLoading"
              :items="cities"
              :label="$tt.capitalize($t('labels.city'))" />
          </div>
          <div v-else>
            <v-text-field
              outlined
              name="user-city"
              v-model="user.city"
              :disabled="isLoading"
              :label="$tt.capitalize($t('labels.city'))" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="7">
          <v-text-field
            outlined
            name="user-distributor"
            v-model="user.distributor"
            :label="$tt.capitalize($t('labels.distributor'))" />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            disabled
            outlined
            name="user-dial-code"
            v-model="user.dialCode"
            v-mask="'+####'"
            :label="$tt.capitalize($t('labels.dialCode'))"
            :rules="valid"
            required />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            name="user-phone"
            v-mask="'##############'"
            v-model="user.phone"
            :label="$tt.capitalize($t('labels.phone'))" />
        </v-col>
      </v-row>
    </f-group-container>

    <f-group-container
      v-allowed="[UserType.Admin, UserType.Master, UserType.TSM]">
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="user.classification"
            name="user-classification"
            :items="userClassification"
            :label="$tt.capitalize($t('labels.userClassification'))"
            :rules="valid"
            required
            outlined
            multiple
            chips />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="user.trainingCategory"
            name="user-trainingCategory"
            :items="trainingCategories"
            :label="$tt.capitalize($t('labels.trainingCategory'))"
            :rules="valid"
            required
            outlined
            multiple
            chips />
        </v-col>
      </v-row>
    </f-group-container>
  </div>
</template>

<script>
import { UserType } from '../../../helpers/user_utils';
import FGroupContainer from '../../shared/GroupContainer.vue';
import validatorRules from '../../../helpers/validator_rules';
import userClassification from '../../../data/userClassifications';
import trainingCategories from '../../../data/trainingCategories';

export default {
  components: {
    FGroupContainer,
  },
  data: () => ({
    hasState: false,
    hasCity: false,
    hasDialCode: true,
    isItFirstLoad: true,
    states: [],
    cities: [],
    userClassification,
    trainingCategories,
    UserType,
    brazilID: '',
  }),
  async created() {
    this.$store.commit('system/loading');
    await this.$store.dispatch('country/loadList');

    this.brazilID = this.$store.state.country.list.items.find(
      (country) => country.name.en == 'BRAZIL'
    )._id;
    this.$store.commit('system/loading', false);
  },
  computed: {
    user() {
      return this.$store.state.user.view;
    },
    allowEdit() {
      return (
        this.$store.getters['auth/user'].type == UserType.Admin ||
        this.$store.getters['auth/user'].type == UserType.TSM ||
        this.$store.getters['auth/user'].type == UserType.Master
      );
    },
    valid() {
      return validatorRules.required(this.$t('rules.required'));
    },
    validEmail() {
      return validatorRules.email(
        true,
        this.$i18n.t('loginPage.pages.register.invalids.email')
      );
    },
    countriesList() {
      const countries = this.$store.state.country.list;
      if (typeof countries.count != 'undefined' && countries.count > 0) {
        var list = countries.items.map((country) => {
          return {
            _id: country._id,
            name:
              country.name[this.$i18n.locale] == 'NULL'
                ? country.name['en']
                : country.name[this.$i18n.locale],
          };
        });
        return list;
      }
    },
  },
  watch: {
    user: function () {
      this.checkIfAllDataIsLoaded();
    },
    countriesList: function () {
      this.checkIfAllDataIsLoaded();
    },
  },
  methods: {
    belongsToBrazil() {
      var errors = [];
      if (this.user.country == this.brazilID && !this.user.state) {
        errors.push(this.$t('rules.required'));
      }
      return errors;
    },
    onChangeLang(value) {
      this.user.lang = value;
    },
    checkIfAllDataIsLoaded() {
      this.states = [];
      if (this.user.country && this.countriesList && this.isItFirstLoad) {
        this.isItFirstLoad = false;
        this.getStates();
        this.getCities();
      }
      if (!this.user.dialCode) this.setDialCode();
    },
    clearStateAndCity() {
      this.user.state = '';
      this.user.city = '';
    },
    async getStates() {
      this.states = [];
      this.cities = [];
      this.$store.commit('system/loading');
      const result = await this.$store.dispatch(
        'country/listStates',
        this.user.country
      );
      this.$store.commit('system/loading', false);
      if (result.error != true) {
        this.states = result;
      }
    },
    async getCities() {
      this.cities = [];
      const payload = {
        _id: this.user.country,
        state: this.user.state,
      };
      this.$store.commit('system/loading');
      const result = await this.$store.dispatch('country/listCities', payload);
      this.$store.commit('system/loading', false);
      if (result.error != true && result.length != 0) {
        this.cities = result;
      }
    },
    async setDialCode() {
      const result = await this.$store.dispatch(
        'country/getDialCode',
        this.user.country
      );
      if (typeof result != 'undefined') {
        this.user.dialCode = result.dialCode;
        this.hasDialCode = true;
      } else {
        this.hasDialCode = false;
        this.user.dialCode = '';
      }
    },
  },
};
</script>
